<template>
  <div class="content-bank">
    <div class="content-bank__title">Selecciona un método de pago</div>
    <div class="content-bank__cont_optbank">
      <div class="content-bank__cont_optbank__opt">
        <div class="img_bank" :class="selectedType == 2 ? 'selected' : ''" @click="clickSelectedtypePayment(2)">
          <img src="/images/banco-azteca-logo.png" />
        </div>
        <div class="text_bank" :class="selectedType == 2 ? 'selected-text' : ''">Banco Azteca</div>
      </div>
      <div></div>
      <div class="content-bank__cont_optbank__opt">
        <div class="img_bank" :class="selectedType == 1 ? 'selected' : ''" @click="clickSelectedtypePayment(1)">
          <img src="/images/tarjetas.png" />
        </div>
        <div class="text_bank" :class="selectedType == 1 ? 'selected-text' : ''">Mastercard, VISA y AMEX</div>
      </div>
    </div>
    <JumboButton :value="'Siguiente'" @click.native="clickNextValuePayment" class="success-button-register" />
    <VueRecaptcha
      ref="recaptcha"
      @verify="onCaptchaVerified"
      @expired="resetCaptcha"
      size="invisible"
      :sitekey="captchaKey"
    />
    <script
      type="application/javascript"
      src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
      defer
    />
  </div>
</template>

<script>
import JumboButton from '@/components/Form/JumboButton';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import captcha from '@/data/captcha';
export default {
  data: () => ({
    selectedType: 1,
    captchaKey: captcha.key,
  }),
  components: { JumboButton, VueRecaptcha },
  async mounted() {
    this.SET_TYPE_PAYMENT_ID(1);
  },
  computed: {
    ...mapGetters('registerGeneral', ['getTypePayment']),
  },
  methods: {
    ...mapMutations('registerGeneral', ['SET_TYPE_PAYMENT_ID']),
    ...mapActions('registerGeneral', ['typePaymentNext']),
    clickSelectedtypePayment(id) {
      this.selectedType = id;
      this.SET_TYPE_PAYMENT_ID(id);
    },
    clickNextValuePayment() {
      this.typePaymentNext();
    },
    onCaptchaVerified(token) {
      this.resetCaptcha();
      this.next(token);
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    async next() {
      this.SET_AUTH_COMPONENT('CardForm');
    },
  },
};
</script>
<style lang="scss" scoped>
.selected {
  border: solid 3px #3397e9 !important;
  color: #3397e9 !important;
}
.selected-text {
  color: #3397e9 !important;
}
.content-bank {
  width: 472px;
  display: flex;
  flex-flow: column;
  align-items: center;
  @media screen and (max-width: 540px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  &__title {
    width: 373px;
    height: 33px;
    font-family: 'Circular-Std-Bold';
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 33px;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    @media screen and (max-width: 540px) {
      width: 100%;
      padding-left: 10px;
      font-size: 20px;
    }
    @media screen and (max-width: 365px) {
      font-size: 18px;
    }
    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }
  &__cont_optbank {
    display: grid;
    width: 472px;
    grid-template-columns: 225px 22px 225px;
    margin-top: 91px;
    @media screen and (max-width: 540px) {
      width: 100%;
      grid-template-columns: 47% 6% 47%;
      margin-top: 51px;
    }
    &__opt {
      display: grid;
      grid-template-columns: 225px;
      @media screen and (max-width: 540px) {
      }
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}
.success-button-register {
  width: 100% !important;
  border-radius: 7px;
  margin-top: 90px;
  @media screen and (max-width: 540px) {
    margin-top: 70px;
  }
}
.img_bank {
  width: 225px;
  height: 85px;
  margin: 0 22px 0 0;
  padding: 10px 16px 16px 16px;
  border-radius: 9.3px;
  border: solid 3px #dadada;
  background-color: #fff;
  @media screen and (max-width: 540px) {
    width: 175px;
    padding: 16px 16px 16px 16px;
  }
  @media screen and (max-width: 440px) {
    width: 184px;
  }
  @media screen and (max-width: 430px) {
    width: 175px;
  }
  @media screen and (max-width: 410px) {
    width: 165px;
  }
  @media screen and (max-width: 390px) {
    width: 155px;
    padding: 19px 16px 16px 16px;
  }
  @media screen and (max-width: 365px) {
    width: 145px;
  }
  @media screen and (max-width: 340px) {
    width: 135px;
    padding: 24px 16px 16px 16px;
  }
  @media screen and (max-width: 320px) {
    width: 125px;
  }
  @media screen and (max-width: 300px) {
    width: 123px;
    padding: 27px 16px 16px 16px;
  }
}
.text_bank {
  width: 225px;
  height: 23px;
  font-family: 'Avenir-Pro-Medium';
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-top: 15px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  text-align: center;
  @media screen and (max-width: 540px) {
    width: 175px;
    font-size: 14px;
  }
  @media screen and (max-width: 440px) {
    width: 184px;
  }
  @media screen and (max-width: 430px) {
    width: 175px;
  }
  @media screen and (max-width: 410px) {
    width: 165px;
    font-size: 12px;
  }
  @media screen and (max-width: 390px) {
    width: 155px;
  }
  @media screen and (max-width: 365px) {
    width: 145px;
    font-size: 10px;
  }
  @media screen and (max-width: 340px) {
    width: 135px;
  }
  @media screen and (max-width: 320px) {
    width: 125px;
  }
  @media screen and (max-width: 300px) {
    width: 123px;
  }
}
</style>

<template>
  <div class="content-plan">
    <div class="card-title">
      Seleccionar Plan
    </div>
    <div class="checkbox-plan">
      <div
        class="checkbox-plan-item"
        :class="getRegisterPlans[1] && selectedPLan === getRegisterPlans[1].title ? ' checkbox-plan-selected' : ''"
        @click="selectPlan(getRegisterPlans[1])"
      >
        {{ getRegisterPlans[1] ? getRegisterPlans[1].title : '' }}
      </div>
      <div
        class="checkbox-plan-item"
        :class="getRegisterPlans[0] && selectedPLan === getRegisterPlans[0].title ? ' checkbox-plan-selected' : ''"
        @click="selectPlan(getRegisterPlans[0])"
      >
        {{ getRegisterPlans[0] ? getRegisterPlans[0].title : '' }}
      </div>
    </div>
    <CardPlan :current-plan="currentPlan" @selected="onClickSelectedPlan(currentPlan)" />
  </div>
</template>

<script>
import CardPlan from '@/views/Auth/CardPlan';
import { mapMutations, mapGetters } from 'vuex';

import { clickBtnFlujoCuentaSeleccionarPlan, clickBtnFlujoCuentaSeleccionar } from '@/utils/analytics';

export default {
  components: { CardPlan },
  data: () => ({
    selectedPLan: 'Mensual',
  }),
  async created() {
    await this.setPlan(2);
  },
  computed: {
    ...mapGetters('registerGeneral', ['getRegisterPlans']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('general', ['getSectionAnalytics']),
    currentPlan() {
      return this.getRegisterPlans.find(r => r.title === this.selectedPLan) || {};
    },
  },
  methods: {
    ...mapMutations('registerGeneral', ['setPlan']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    selectPlan(item) {
      clickBtnFlujoCuentaSeleccionarPlan(item.title.toLowerCase(), this.getPremiumAccount, this.getIsGolstats);
      this.selectedPLan = item.title;
      this.setPlan(item.id);
    },
    onClickSelectedPlan(currentPlan) {
      clickBtnFlujoCuentaSeleccionar({
        plan_id: currentPlan.id,
        plan_name: currentPlan.title,
        plan_price: currentPlan.price,
        plan_currency: currentPlan.currency,
        banner: this.getSectionAnalytics,
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
      this.SET_AUTH_COMPONENT('Register');
    },
  },
};
</script>

<style lang="scss" scoped>
.content-plan {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.card-title {
  font-family: Circular-Std;
  font-size: 17px;
  font-weight: bold;
  line-height: 2.16;
  letter-spacing: -0.41px;
  color: #959595;
}
.checkbox-plan {
  display: flex;
  padding: 2px;
  margin-bottom: 20px;
  justify-content: center;
  border-radius: 20px;
  border: solid 1px #c6c6c6;

  &-item {
    font-family: Avenir-Regular;
    font-size: 13px;
    padding: 10px 25px;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: -0.52px;
    text-align: center;
    color: #737373;
  }
  &-selected {
    border-radius: 17px;
    background-color: #cde8b5;
  }
}
</style>

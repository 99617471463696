<template>
  <div class="login-form">
    <div v-if="formVisble">
      <Input label="Email*" placeholder="Usuario" v-model="form.user" />
      <Input
        label="Contraseña*"
        placeholder="Contraseña"
        type="password"
        v-model="form.password"
        @keyup.native="preSubmit"
      />
      <div class="m-20">
        <JumboButton @click.native="login" class="success-button-register" value="Iniciar Sesión" />
      </div>
      <div class="label-bold m-20">
        <span class="help-button title-recover-password-hover" @click="recoverpassword">¿Olvidaste tu contraseña?</span>
      </div>
      <div class="label-bold m-20">
        <div class="label">No tengo una cuenta</div>

        <div class="link" @click="onClickCreateOne">Crear una</div>
        <div class="link l-20 is-hidden">FAQ</div>
      </div>
    </div>
    <div class="recover-password-form" v-if="!formVisble && getStatusSendEmail != 'success'">
      <div class="title-recover-password">Olvidaste tu contraseña</div>
      <div class="subtitle-recover-password">
        Te enviaremos un email con instrucciones sobre cómo restablecer tu contraseña.
      </div>
      <span v-if="hasError && ['fillRegister', 'checkEmail'].includes(status)" class="error-user">{{ error }}</span>
      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" key="user">
        <Input
          label="E-mail"
          :error="errors[0]"
          :hasError="!!errors[0]"
          placeholder="tucuenta@correo.com"
          v-model="formpassword.user"
        />
      </ValidationProvider>
      <span class="message-error-recovery" v-if="setErrorMsg">
        <i class="mdi mdi-alert-circle" style="margin-right: 5px; font-size: 16px;"></i>No existe una cuenta vinculada
        al correo que proporcionaste.
      </span>
      <div class="m-20">
        <JumboButton
          @click.native="onClickChangePassword"
          class="success-button-register"
          value="Enviar email"
          :disabled="disabledSubmit"
          :class="{ 'disabled-message': disabledSubmit }"
        />
      </div>
      <div class="button-back-recover-password" @click="closerecoverpassword">
        <img width="19" class="arrow-back-recover-password-button" src="/icons/left-arrow.png" />
        <span class="back-recover-password-button">Volver</span>
      </div>
    </div>
    <div class="recover-password-form" v-if="getStatusSendEmail == 'success'">
      <div class="title-recover-password">Revisa tu correo</div>
      <div class="subtitle-recover-password">
        Te hemos enviado un link a tu cuenta de correo registrada, para ayudarte a restablecer tu contraseña.
      </div>
      <img class="check-success-recover-password" src="/icons/maps-and-flags.png" />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Form/Input';
import JumboButton from '@/components/Form/JumboButton';
import { ValidationProvider } from 'vee-validate';
import { isValidEmail } from '@/utils/helpers';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { getIsGS20 } from '@/utils/misc';

import {
  clickBtnFlujoIniciarSesion,
  clickLogin,
  clickBtnFlujoCuentaCrearUna,
  clickOlvidasteLaContrasena,
  clickEnviarRecuperarContrasena,
} from '@/utils/analytics';

export default {
  name: 'LoginMain',
  components: { Input, JumboButton, ValidationProvider },
  data() {
    return {
      formVisble: true,
      setViewForm: false,
      setErrorMsg: false,
      form: {
        user: '',
        password: '',
      },
      status: 'checkEmail',
      formpassword: {
        user: '',
        recovery: 0,
      },
    };
  },
  computed: {
    ...mapGetters('recoverGeneral', ['getStatusSendEmail']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapState('registerGeneral', ['registering', 'error']),
    hasError() {
      return this.error.length > 0;
    },
    disabledSubmit() {
      switch (this.status) {
        case 'checkEmail':
          // eslint-disable-next-line max-len, no-case-declarations
          const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !validEmail.test(this.formpassword.user);
        default:
          return this.formpassword.user === '';
      }
    },
  },
  methods: {
    ...mapActions('loginGeneral', ['doLogin', 'doLoginGS2']),
    ...mapActions('recoverGeneral', ['emailSend']),
    ...mapActions('tips', ['fetchScenarios', 'fetchTournamentsWithScenarios']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('recoverGeneral', ['setStatusSendEmail']),
    ...mapMutations(['setIsSectionLoading']),
    preSubmit(event) {
      if (event.keyCode === 13) {
        this.login();
      }
    },
    recoverpassword() {
      clickOlvidasteLaContrasena(this.getPremiumAccount, this.getIsGolstats);
      this.formVisble = false;
      this.formpassword.recover = 0;
      this.formpassword.user = '';
    },
    closerecoverpassword() {
      this.formVisble = true;
      this.formpassword.recover = 0;
      this.formpassword.user = '';
    },
    async login() {
      try {
        this.setIsSectionLoading(true);
        clickBtnFlujoIniciarSesion(this.getPremiumAccount, this.getIsGolstats);
        const gs2LoginData = {
          username: this.form.user,
          password: this.form.password,
        };

        const hasUserEmail = isValidEmail(this.form.user);
        if (hasUserEmail) {
          await this.doLogin({ form: this.form });
          await this.fetchTournamentsWithScenarios(4);
          // ToDo: Add userId for analytics
          clickLogin({ is_premium: this.getPremiumAccount, golsstats: this.getIsGolstats });
          this.SET_AUTH_COMPONENT(null);
          if (this.$route.name === 'set-bet-user') {
            await this.$router.push('choose-the-risk');
          }
        } else {
          const gsResponse = await this.doLoginGS2({ login: gs2LoginData });
          if (gsResponse) {
            if (gsResponse.user_type === 1) {
              const userEncoded = window.btoa(JSON.stringify(gs2LoginData));
              window.localStorage.setItem('gs2User', userEncoded);
              window.localStorage.setItem('isGS2User', '1');
              window.localStorage.setItem('sysType', gsResponse.type_system);
              const baseUrl = getIsGS20(gsResponse.type_system)
                ? process.env.VUE_APP_GS20_URL
                : process.env.VUE_APP_GS30_URL;
              const gsUrl = `${baseUrl}/login/?user=${userEncoded}`;
              clickLogin({ is_premium: this.getPremiumAccount, golstats: gsResponse.user_type });
              if (this.$route.name === 'widget' || this.$route.query.is_iframe) {
                window.open(gsUrl, '_blank');
                this.SET_AUTH_COMPONENT(null);
                this.setIsSectionLoading(false);
              } else {
                window.location.replace(gsUrl);
              }
            } else if (gsResponse.user_type === 4) {
              window.localStorage.setItem('isGS1User', '1');
              // todo redirect gs 1
              let userCode = Buffer.from(this.form.user).toString('base64');
              let passCode = Buffer.from(this.form.password).toString('base64');
              window.localStorage.setItem('gs1UserP1', userCode);
              window.localStorage.setItem('gs1UserP2', passCode);
              window.localStorage.setItem('isGS1User', '1');
              clickLogin({ is_premium: this.getPremiumAccount, golstats: gsResponse.user_type });
              // this.SET_AUTH_COMPONENT(null);
              window.location.replace('https://professional.golstats.com/login2/' + userCode + '/' + passCode);
            }
          }
        }
      } catch (error) {
        // ToDo: Add message if problem with login
        this.setIsSectionLoading(false);
        throw new Error(error);
      } finally {
        if (this.$route.name !== 'match-analysis') {
          this.setIsSectionLoading(false);
        }
      }
    },
    async recoverPassword() {
      try {
        this.formpassword.recovery = 1;
        await this.emailSend(this.formpassword);
        if (this.getStatusSendEmail != 'success') {
          this.setErrorMsg = true;
        }
        setTimeout(this.changeStatus, 5000);
      } catch (error) {
        // ToDo: Add message if problem with login
      }
    },
    setStatusMail() {
      this.setStatusSendEmail('none');
    },
    statusErrorChange() {
      this.setErrorMsg = false;
    },
    changeStatus() {
      this.setStatusSendEmail('none');
      if (this.getStatusSendEmail == 'success') {
        this.setViewForm = false;
        this.formVisble = true;
        this.formpassword.recover = 0;
        this.formpassword.user = '';
      } else {
        setTimeout(this.statusErrorChange, 500);
      }
    },
    onClickCreateOne() {
      clickBtnFlujoCuentaCrearUna(this.getPremiumAccount, this.getIsGolstats);
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
    onClickChangePassword() {
      clickEnviarRecuperarContrasena(this.getPremiumAccount, this.getIsGolstats);
      this.setViewForm = true;
      this.recoverPassword();
    },
  },
};
</script>

<style scoped>
.login-form {
  max-width: 450px;
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: center;
}
.l-20 {
  padding-left: 20px;
}
.title-recover-password {
  margin: 0 75px 4px 76px;
  font-family: 'Circular-Std';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.71px;
  text-align: center;
  color: #494a4b;
}
.message-error-recovery {
  color: red;
  font-family: 'Avenir-Roman';
  padding: 2px;
  margin-top: -10px;
  margin-left: 15px;
  float: left;
  font-size: 12px;
  text-align: start;
}

.title-recover-password-hover:hover {
  text-decoration: underline;
}
.subtitle-recover-password {
  margin: 4px 0 0;
  font-family: 'Avenir-Roman';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  text-align: center;
  color: #494a4b;
}
.button-back-recover-password {
  cursor: pointer;
}
.arrow-back-recover-password-button {
  width: 20px;
  height: 20px;
  margin: 1px 5px 0 0;
  padding-top: 7px;
  padding-left: 5px;
}
.back-recover-password-button {
  width: 48px;
  height: 21px;
  margin: 0 0 0 5px;
  font-family: 'Avenir-Pro-Bold';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.09px;
  text-align: center;
  color: #353535;
}
.check-success-recover-password {
  margin-top: 30px;
}
.link {
  color: #3197f4;
  text-decoration: underline;
  cursor: pointer;
}
.label {
  font-family: Avenir-Regular;
  padding-right: 5px;
}
.help-button:hover {
  cursor: pointer;
}
.label-bold {
  font-family: Avenir-Demi;
  font-size: 18px;
  line-height: 1.13;
  letter-spacing: -0.1px;
  text-align: center;
  color: #494a4b;
  align-self: center;
  align-items: baseline;
  display: inline-flex;
}
.m-20 {
  margin: 25px 15px;
}
.success-button-register {
  width: 100% !important;
  border-radius: 7px;
}
</style>

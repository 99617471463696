<template>
  <div class="register-form">
    <span v-if="hasError && ['fillRegister', 'checkEmail'].includes(status)" class="error-user">{{ error }}</span>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div v-if="status === 'checkEmail'">
        <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" key="email">
          <Input
            label="E-mail*"
            placeholder="tucuenta@correo.com"
            type="email"
            :hasError="!!errors[0]"
            v-model="form.email"
            :error="errors[0]"
          />
        </ValidationProvider>
      </div>
      <div v-else-if="status === 'fillRegister'">
        <ValidationProvider rules="required" name="username" v-slot="{ errors }" key="username">
          <Input
            label="Nombre del usuario*"
            placeholder="Nombre"
            disableAutocomplete
            v-model="form.username"
            :hasError="!!errors[0]"
            :error="errors[0]"
          />
        </ValidationProvider>
        <ValidationProvider rules="required" vid="password" name="Password" v-slot="{ errors }">
          <Input
            label="Contraseña*"
            placeholder="Contraseña"
            type="password"
            disableAutocomplete
            v-model="form.password"
            :hasError="!!errors[0]"
            :error="errors[0]"
          />
        </ValidationProvider>
        <ValidationProvider rules="required|confirmed:password" name="Password Confirmation" v-slot="{ errors }">
          <Input
            label="Confirmar Contraseña*"
            placeholder="Confirmar Contraseña"
            type="password"
            disableAutocomplete
            v-model="form.confirmPassword"
            :hasError="!!errors[0]"
            :error="errors[0]"
          />
        </ValidationProvider>
        <ValidationProvider :rules="{ required: { allowFalse: false } }" name="terms" v-slot="{ errors, valid }">
          <BField :type="{ 'is-error': errors[0], 'is-success': valid }" :message="errors">
            <label class="checkbox">
              <input type="checkbox" v-model="terms" />
              <span class="label-term">Acepto los</span>
              <RouterLink to="/terms" target="_blank" class="label-term-black"> Términos y condiciones</RouterLink>
            </label>
          </BField>
        </ValidationProvider>
      </div>
      <div v-else-if="['existUserPremium', 'existUser'].includes(status)">
        <ValidationProvider
          rules="required"
          vid="password"
          name="Password"
          v-slot="{ errors, valid }"
          :key="'emailPassword'"
        >
          <Input
            label="Contraseña*"
            placeholder="Contraseña"
            type="password"
            v-model="form.password"
            :hasError="!!errors[0]"
            :error="errors[0]"
          />
        </ValidationProvider>
        <div class="message-info">
          {{ message.start }}<strong>{{ message.email }}</strong> {{ message.end }}
        </div>
      </div>
      <div class="m-20">
        <JumboButton
          @click.native="validate"
          :value="status === 'existUserPremium' ? 'Iniciar Sesión' : 'Siguiente'"
          :disabled="disabledSubmit"
          class="success-button-register"
          :class="{ 'disabled-message': disabledSubmit }"
        />
      </div>
      <div class="label-bold m-20">
        <span class="label">Ya tengo una cuenta</span>
        <span class="link" @click="SET_AUTH_COMPONENT('Login')">Iniciar Sesión</span>
        <span class="link l-20 is-hidden">FAQ</span>
      </div>
    </ValidationObserver>
    <VueRecaptcha
      ref="recaptcha"
      @verify="onCaptchaVerified"
      @expired="resetCaptcha"
      size="invisible"
      :sitekey="captchaKey"
    />
    <script
      type="application/javascript"
      src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
      defer
    />
  </div>
</template>

<script>
import Input from '@/components/Form/Input';
import JumboButton from '@/components/Form/JumboButton';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
// import { isValidPassword } from '@/utils/helpers';
import VueRecaptcha from 'vue-recaptcha';
import captcha from '@/data/captcha';
// import password from '@/data/user/password';
import { termsStoreNames } from '@/store/terms';

import {
  clickBtnFlujoCuentaCrearCuentaSig,
  clickBtnFlujoCuentaCrearCuentaSig2,
  clickBtnFlujoCuentaExisteIniciarS,
  clickLogin,
} from '@/utils/analytics';

export default {
  name: 'Register',
  components: { Input, JumboButton, VueRecaptcha, ValidationObserver, ValidationProvider },
  data: () => ({
    form: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    terms: false,
    status: 'checkEmail',
    message: {},
    captchaKey: captcha.key,
  }),
  computed: {
    ...mapState('registerGeneral', ['registering', 'error']),
    ...mapGetters('general', ['getSectionAnalytics']),
    ...mapGetters({
      lastTermsVersion: termsStoreNames.getStoreNames().getters.GET_TERMS_VERSION,
    }),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    disabledSubmit() {
      switch (this.status) {
        case 'checkEmail':
          // eslint-disable-next-line max-len, no-case-declarations
          const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !validEmail.test(this.form.email);
        case 'fillRegister':
          return (
            this.form.username === '' ||
            this.form.password === '' ||
            this.form.email === '' ||
            this.form.confirmPassword === '' ||
            !this.terms ||
            this.registering ||
            this.form.password !== this.form.confirmPassword
          );
        default:
          return this.form.password === '';
      }
    },
    hasError() {
      return this.error.length > 0;
    },
  },
  async mounted() {
    if (!('date' in this.lastTermsVersion)) {
      await this.fetchLastTermsVersion();
    }
  },
  beforeDestroy() {
    this.setError('');
  },
  methods: {
    ...mapMutations('registerGeneral', ['setUi', 'setName', 'setError']),
    ...mapActions('registerGeneral', ['verifyEmail', 'registerUser', 'validateRegisteredUser']),
    ...mapActions('loginGeneral', ['doLogin']),
    ...mapActions('alertGeneral', ['setAlert']),
    ...mapActions({
      fetchLastTermsVersion: termsStoreNames.getStoreNames().actions.FETCH_LAST_TERMS_VERSION,
    }),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapActions('tips', ['fetchScenarios', 'fetchTournamentsWithScenarios']),

    async validate() {
      if (this.status === 'checkEmail') {
        try {
          clickBtnFlujoCuentaCrearCuentaSig({
            value: this.status,
            banner: this.getSectionAnalytics,
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          });
          await this.verifyEmail(this.form.email);
          this.status = 'fillRegister';
        } catch (error) {
          switch (error.code) {
            case 1:
              this.status = 'existUser';
              this.message.start = 'Ya existe un registro inconcluso con esta cuenta ';
              this.message.email = this.form.email;
              this.message.end = '. Para completar el registro ingresa tu contraseña y da click en siguiente.';
              break;
            case 4:
              this.status = 'existUserPremium';
              this.message.start = 'Ya existe una cuenta Premium ligada a este correo ';
              this.message.email = this.form.email;
              this.message.end = '. Si quieres iniciar sesión ingresa tu contraseña.';
              break;
          }
        }
      } else {
        // const PASS_VALID = await isValidPassword(this.form.password);
        // if (PASS_VALID || this.status === 'existUserPremium') {
        await this.$refs.recaptcha.execute();
        // } else {
        //   this.setAlert({
        //     type: 'error',
        //     message: password.failedValidation,
        //     duration: 15000,
        //     passwordInvalid: true,
        //   });
        // }
      }
    },
    onCaptchaVerified(token) {
      this.resetCaptcha();
      this.next(token);
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    async next(token) {
      let body = null;
      switch (this.status) {
        case 'fillRegister':
          clickBtnFlujoCuentaCrearCuentaSig2({
            value: this.status,
            banner: this.getSectionAnalytics,
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          });
          body = {
            user: this.form.email,
            password: this.form.password,
            account_name: this.form.username,
            recaptcha: token,
            term_id: this.lastTermsVersion.t_id,
          };
          await this.registerUser(body);
          break;
        case 'existUserPremium':
          clickBtnFlujoCuentaExisteIniciarS(this.status, this.getPremiumAccount, this.getIsGolstats);
          body = {
            user: this.form.email,
            password: this.form.password,
          };
          await this.doLogin({ form: body });
          // await this.fetchScenarios(4);
          await this.fetchTournamentsWithScenarios(4);
          clickLogin({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
          this.SET_AUTH_COMPONENT(null);
          break;
        case 'existUser':
          clickBtnFlujoCuentaCrearCuentaSig2({
            value: this.status,
            banner: this.getSectionAnalytics,
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          });
          body = {
            user: this.form.email,
            password: this.form.password,
          };
          await this.validateRegisteredUser(body);
          break;
      }
    },
  },
};
</script>

<style>
.is-error {
  color: #ee6161;
}
.checkbox {
  width: 100%;
  text-align: start;
  padding-left: 15px;
}
.label-term {
  padding-left: 5px;
  font-family: Avenir-Regular;
  color: #494a4b;
}
.label-term-black {
  font-family: Avenir-Demi;
  color: #494a4b;
}
.register-form {
  max-width: 450px;
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: center;
}
.disabled-message {
  background-color: #e5e5e5 !important;
  cursor: auto !important;
}

.l-20 {
  padding-left: 20px;
}
.link {
  color: #3197f4;
  text-decoration: underline;
  cursor: pointer;
}
.label {
  font-family: Avenir-Regular;
  padding-right: 5px;
}
.label-bold {
  font-family: Avenir-Demi;
  font-size: 18px;
  line-height: 1.13;
  letter-spacing: -0.1px;
  text-align: center;
  color: #494a4b;
  align-self: center;
  align-items: baseline;
  display: inline-flex;
}
.m-20 {
  margin: 25px 15px;
}
.help {
  margin: -5px 0 -7px 17px !important;
  text-align: left !important;
}
.error-user {
  color: crimson;
  font-size: small;
}
.grecaptcha-badge {
  visibility: hidden !important;
}
.message-info {
  margin: 10px 15px;
  padding: 10px;
  font-family: 'Avenir-Regular';
  background-color: #f7f7f7;
}
.success-button-register {
  width: 100% !important;
  border-radius: 7px;
}
</style>

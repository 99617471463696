<template>
  <div class="login-modal-container">
    <div class="columns is-gapless">
      <div class="column header-page">
        <span v-if="isLogin">Usuario Premium</span>
        <span v-else>Crear cuenta Premium</span>
        <div class="close-button" @click="onClickClose">
          <div class="close"><div class="text-close-button">Cerrar</div></div>
          <img src="/icons/icn-close-white-no-cicrle.svg" alt="close" width="20" />
        </div>
      </div>
    </div>
    <div class="columns is-gapless is-centered">
      <div class="column is-justify-content-start">
        <div class="container-component">
          <div
            class="button-links"
            :class="['select-plan', 'payment'].includes($route.name) ? 'is-hidden-desktop' : ''"
          >
            <div class="button" :class="{ 'is-selected': isLogin }" @click="onClickLogin" style="margin-right: 5px">
              INICIAR SESIÓN
            </div>
            <div class="button" :class="{ 'is-selected': !isLogin }" @click="onClickCreateAccount">
              CREAR CUENTA
            </div>
          </div>
          <Component :is="authComponent"></Component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Login from '@/views/Auth/Login';
import LoginMain from '@/views/Auth/LoginMain';
import Register from '@/views/Auth/Register';
import SelectPlan from '@/views/Auth/SelectPlan';
import CardForm from '@/views/Auth/CardForm';
import SelectBank from '@/views/Auth/SelectBank';

import {
  clickBtnFlujoCuentaCerrar,
  clickBtnFlujoCuentaIniciarSesionP,
  clickBtnFlujoCuentaCrearCuentaP,
} from '@/utils/analytics';

export default {
  name: 'LoginModalContent',
  components: {
    Login,
    LoginMain,
    Register,
    SelectPlan,
    CardForm,
    SelectBank,
  },
  computed: {
    ...mapState('auth', ['authComponent']),
    ...mapState('profileGeneral', ['plans']),
    ...mapState(['isSectionLoading', 'displayWidth']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    isMobile() {
      return this.displayWidth < 769;
    },
    isLogin() {
      return this.authComponent === 'Login' || this.authComponent === 'LoginMain';
    },
  },
  async created() {
    if (!this.plans.length) {
      await this.getPlans();
    }
  },
  methods: {
    ...mapActions('profileGeneral', ['getPlans']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('general', ['setSectionAnalytics']),
    onClickClose() {
      this.setSectionAnalytics('Banner_Home');
      clickBtnFlujoCuentaCerrar(this.getPremiumAccount, this.getIsGolstats);
      this.SET_AUTH_COMPONENT(null);
    },
    onClickLogin() {
      clickBtnFlujoCuentaIniciarSesionP(this.getPremiumAccount, this.getIsGolstats);
      this.SET_AUTH_COMPONENT('Login');
    },
    onClickCreateAccount() {
      clickBtnFlujoCuentaCrearCuentaP(this.getPremiumAccount, this.getIsGolstats);
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
  },
};
</script>

<style scoped lang="scss">
.login-modal-container {
  background: white;
  min-height: 812px;
  border-radius: 19px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  @media screen and (max-width: 1020px) {
    width: 920px;
    margin: 0 auto;
  }
  @media screen and (max-width: 940px) {
    width: 820px;
    margin: 0 auto;
  }
  @media screen and (max-width: 840px) {
    width: 720px;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    // min-height: 690px;
  }
  @media screen and (max-width: 740px) {
    width: 620px;
    margin: 0 auto;
  }
  @media screen and (max-width: 640px) {
    width: 520px;
    margin: 0 auto;
  }
  @media screen and (max-width: 540px) {
    width: 420px;
    margin: 0 auto;
  }
  @media screen and (max-width: 440px) {
    width: 100%;
    margin: 0 auto;
    border-radius: 0px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
}
.speech-invesment {
  font-family: Avenir-Regular;
  max-width: 550px;
  margin: 0 15px;
  color: #494a4b;
  span:first-child {
    font-family: Avenir-Demi;
  }
}
.text-close-button {
  display: inline-block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.close-button {
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 0.7vw;
  font-size: 16px;
  letter-spacing: -0.04px;
  text-align: right;
  background-color: #494a4b;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 6em;
  padding: 0 0.8em;
  border-radius: 13px;

  &.is-hidden-tablet {
    top: -15px;
  }
  @media screen and (max-width: 768px) {
    border-radius: 33px;
    width: 32px;
    height: 32px;
    padding: 0 0;
    font-size: 28px;
    text-align: center;
    justify-content: center;
  }
}
.video {
  width: 100%;
  max-width: 700px;
}
.header-page {
  height: 80px;
  display: flex;
  background-color: #4b4b4b;
  align-items: center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  justify-content: center;
  position: relative;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.07);
  background-color: #fcfcfc;
  span {
    font-family: Circular-Std;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.17px;
    text-align: center;
    color: #494a4b;
  }
}
.columns {
  width: 100%;
  .column {
    padding: 40px 50px;
  }
}
.button {
  font-family: Avenir-Pro-Bold;
  padding: 15px 40px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 3.07;
  letter-spacing: -0.08px;
  text-align: center;
  border: none;
  color: #494a4b;
}
.button-links {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}
.is-selected {
  background-color: #efefef;
}
.is-justify-content-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}
@media screen and (min-width: 769px) {
  .is-justify-content-start {
    width: 40%;
  }
}
.mt-40 {
  margin-top: 40px !important;
}
.outline-right {
  position: relative;
}
.border-right {
  width: 2px;
  height: 50%;
  position: absolute;
  right: 0;
  top: 25%;
  background-color: #cdcdcd;
}
@media screen and (max-width: 880px) {
  .button {
    padding: 5px 15px;
  }
  section {
    position: relative;
  }
  @media screen and (max-width: 450px) {
    .header-page span {
      font-size: 22px;
    }
    .close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 2;
    }
  }
}
@media screen and (max-width: 768px) {
  .mt-40 {
    margin-top: 0 !important;
  }
  .columns {
    width: 100%;
    .column {
      padding: 0;
    }
  }
  .button-links {
    padding: 0 0 10px;
  }
  .button {
    padding: 5px 10px;
  }
  section {
    position: relative;
  }
}
.container-component {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  @media screen and (min-height: 160px) {
    height: 80px;
    overflow: auto;
  }
  @media screen and (min-height: 260px) {
    height: 150px;
    overflow: auto;
  }
  @media screen and (min-height: 360px) {
    height: 200px;
    overflow: auto;
  }
  @media screen and (min-height: 460px) {
    height: 350px;
    overflow: auto;
  }
  @media only screen and (min-height: 568px) {
    height: 395px;
    overflow: auto;
  }
  @media screen and (min-height: 640px) {
    height: 480px;
    overflow: auto;
  }
  @media screen and (min-height: 667px) {
    height: 500px;
    overflow: auto;
  }
  @media screen and (min-height: 731px) {
    height: 550px;
    overflow: auto;
  }
  @media screen and (min-height: 812px) {
    height: 720px;
    overflow: auto;
  }
}
</style>

// @ts-check
/**
 *
 * @param {string} timeZone
 * @return Boolean
 */
export function isValidTimeZone(timeZone) {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch (ex) {
    return false;
  }
}

/**
 * @author José Rafael Gutierrez
 * @param {string} text
 * @return Boolean
 */
export function isValidEmail(text) {
  // eslint-disable-next-line max-len
  const EXP_REG_EMAIL = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return EXP_REG_EMAIL.test(text);
}

/**
 * @author José Rafael Gutierrez
 * @param value
 * @returns {number}
 */
export function parseInteger(value) {
  return value === '' ? 0 : parseInt(value);
}

/**
 * @author José Rafael Gutierrez
 * @param {string} text
 * @return Boolean
 */
export function isValidPassword(text) {
  // eslint-disable-next-line max-len
  // const EXP_REG_PASSWORD = new RegExp('^(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{6,10}$');
  const EXP_REG_PASSWORD = new RegExp('^.{6,32}$');

  return EXP_REG_PASSWORD.test(text);
}
